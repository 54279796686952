import {
  fetch
} from '../common/js/merge_bak.js'

import md5 from 'js-md5';
import router from '../router'; //跳转路由用
import {
  Message,
  Notification
} from 'element-ui'

export function Login(options) { //管理员登录
  const url = '/api/oauth/token'

  //const url = VUE_WEB_URL.baseUrl + 'oauth/token'
  return fetch(url, options, 'post','',1)
}


export function eworkgroupglist(options) { //群列表
  const url = '/api/eworkgroup/getList'
  return fetch(url, options, 'post','',0)
}
export function eworkgroupsave(options) { //群列表新增
  const url = '/api/eworkgroup/save'
  return fetch(url, options, 'post','',0)
}
export function eworkgroupupdate(options) { //群列表修改
  const url = '/api/eworkgroup/update'
  return fetch(url, options, 'post','',0)
}

export function eworkgroupdelete(options) { //群列表删除
  const url = '/api/eworkgroup/delete'
  return fetch(url, options, 'post','',0)
}
export function grecruitmentgetList(options) { //招工列表
  const url = '/api/grecruitment/getList'
  return fetch(url, options, 'post','',0)
}
export function grecruitmentgetsave(options) { //招工新增
  const url = '/api/grecruitment/save'
  return fetch(url, options, 'post','',0)
}
export function grecruitmentgetupdate(options) { //招工修改
  const url = '/api/grecruitment/update'
  return fetch(url, options, 'post','',0)
}
export function grecruitmentgetdelete(options) { //招工删除
  const url = '/api/grecruitment/delete'
  return fetch(url, options, 'post','',0)
}

export function getTypeWorkListByPage(options) { //工种选择
  const url = '/api/eTypeWork/getTypeWorkListByPage'
  return fetch(url, options, 'get','',0)
}
export function groupusergetList(options) { //会员注册
  const url = '/api/groupuser/getList'
  return fetch(url, options, 'post','',0)
}

export function groupuserupdate(options) { //会员分销设置
  const url = '/api/groupuser/update'
  return fetch(url, options, 'post','',0)
}


export function gvipgetList(options) { //消费记录
  const url = '/api/gvip/getList'
  return fetch(url, options, 'post','',0)
}
export function gusergroupgetList(options) { //消费记录
  const url = '/api/gusergroup/getList'
  return fetch(url, options, 'post','',0)
}
export function indexpagedata(options) { //首页展示数据
  const url = '/api/index/data'
  return fetch(url, options, 'get','',0)
}
export function gfactorylist(options) { //工厂招工列表
  const url = '/api/gfactory/list'
  return fetch(url, options, 'post','',0)
}
export function gfactorysave(options) { //工厂招工新增
  const url = '/api/gfactory/save'
  return fetch(url, options, 'post','',0)
}
export function gfactorydelete(options) { //工厂招工删除
  const url = '/api/gfactory/delete'
  return fetch(url, options, 'post','',0)
}
export function gfactoryupdate(options) { //工厂招工修改
  const url = '/api/gfactory/update'
  return fetch(url, options, 'post','',0)
}


export function gsignupgetList(options) { //工厂报名列表
  const url = '/api/gsignup/getList'
  return fetch(url, options, 'post','',0)
}
export function gsignupdelete(options) { //工厂报名删除
  const url = '/api/gsignup/delete'
  return fetch(url, options, 'post','',0)
}
export function gsignupupdate(options) { //工厂报名处理
  const url = '/api/gsignup/update'
  return fetch(url, options, 'post','',0)
}
export function addeTypeWork(options) { //新增工种类型
  const url = '/api/eTypeWork'
  return fetch(url, options, 'post','',0)
}
export function eTypeWorkdel(options) { //新增工种类型
  const url = '/api/eTypeWork/'+options.id
  options = {}
  return fetch(url, options, 'DELETE','',0)
}

export function eTypeWorkdeledit(options) { //修改工种类型
  const url = '/api/eTypeWork/' + options.id
  return fetch(url, options, 'PUT','',0)
}
export function addeeJobType(options) { //新增子工种类型
  const url = '/api/eJobType'
  return fetch(url, options, 'post','',0)
}
export function eeJobTypeedit(options) { //编辑子工种类型
  const url = '/api/eJobType/'+options.id
  return fetch(url, options, 'PUT','',0)
}
export function eJobTypedel(options) { //删除子工种类型
  const url = '/api/eJobType/'+options.id
  options = {}
  return fetch(url, options, 'DELETE','',0)
}
export function gwithdrawallist(options) { //后台提现列表
  const url = '/api/gwithdrawal/list'
  return fetch(url, options, 'POST','',0)
}
export function gwithdrawalupdate(options) { //后台提现通过
  const url = '/api/gwithdrawal/update'
  return fetch(url, options, 'POST','',0)
}
export function gdistributionlist(options) { //推广大厅 佣金比例
  const url = '/api/gdistribution/list'
  return fetch(url, options, 'POST','',0)
}
export function gdistributionsaveupdate(options) { //推广大厅 佣金比例修改
  const url = '/api/gdistribution/update'
  return fetch(url, options, 'POST','',0)
}

export function grecruitmentdoStanding(options) { //招工信息上下价
  const url = '/api/grecruitment/doStanding/'+options.id;
  //options={}
  return fetch(url, options, 'get','',0)
}

export function gentrustadminList(options) { //委托找活列表
  const url = '/api/gentrust/adminList';
  //options={}
  return fetch(url, options, 'post','',0)
}
export function gentrustadopt(options) { //委托找活列表 通过
  const url = '/api/gentrust/adopt/' + options.id;
  options={}
  return fetch(url, options, 'get','',0)
}
export function gentrustrefuse(options) { //委托找活列表 拒绝
  const url = '/api/gentrust/refuse/' + options.id;
  options={}
  return fetch(url, options, 'get','',0)
}
export function gentrustcomplete(options) { //委托找活列表 服务完成
  const url = '/api/gentrust/complete/' + options.id;
  options={}
  return fetch(url, options, 'get','',0)
}
export function gentrustfail(options) { //委托找活列表 服务失败
  const url = '/api/gentrust/fail/' + options.id;
  options = {}
  return fetch(url, options, 'get','',0)
}










export function uploadstatic(options) { //文件上传
  const url = '/api/file/upload-static'
  return fetch(url, options, 'post','file',0)
}
/* export function Login(options) { //管理员登录
  const url = VUE_WEB_URL.baseUrl + 'auth/token'
  return fetch(url, options, 'post')
}
export function RefreshToken(options) { //更新token
  let params = options;
  let timestamp = Date.now();
  let Sign = md5(timestamp + secret_key + 'ererr')
  params['sign'] = Sign;
  params['timestamp'] = timestamp;
  const url = VUE_WEB_URL.baseUrl + '/webadmin/adminuser/refreshtoken'
  return fetch(url, params, 'post')
} */

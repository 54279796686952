import axios from 'axios';
import router from '../../router'; //跳转路由用
//import $ from 'jquery';
import store from '../../store'
import Vue from "vue";
import {
	Message,
	Notification,
	MessageBox
} from 'element-ui'

import { RefreshToken } from '../../api/api.js'
import md5 from 'js-md5';
import NProgress from 'nprogress' //页面加载顶部进度条
import 'nprogress/nprogress.css'

// 是否正在刷新的标记
let isRefreshing = false
// 重试队列，每一项将是一个待执行的函数形式
let retryRequests = []

const request = axios.create({
	baseURL: '',
	timeout: 50000,
	withCredentials: true, // cookie跨域必备
})
// http request 拦截器 Request
let notokenurl=[
	'/api/oauth/token',

]
request.interceptors.request.use(
	(config) => {

		if(notokenurl.indexOf(config.url)!=-1){
			config.headers = {
				"Content-Type": "application/json",
				'Authorization': '',
			};
			return config
		} else {
			if(getLocalToken() != null && getLocalToken() != 'null') {
				config.headers = {
					"Content-Type": "application/json",
					'Authorization': getLocalToken(),
				};
				return config
			} else {
				Message.error('未查询到登录信息请重新登录！')
				router.replace('siginIn');
				window.localStorage.clear();
				return
			}
		}
		/*if(getLocalToken() != null && getLocalToken() != 'null') {
				config.headers = {
					"Content-Type": "application/json",
					'Authorization': getLocalToken(),
				};
				return config
			} else {
				Message.error('未查询到登录信息请重新登录！')
				router.replace('siginIn');
				window.localStorage.clear();
				return
			}*/

	},
	(error) => {
		Promise.reject(error)
	}
)

// http response 拦截器 Response
request.interceptors.response.use(
	(response) => {
		NProgress.done()
		// code == 0: 成功
		const res = response.data

      if(res.status == '401'){
        router.replace('siginIn');
        window.localStorage.clear();
        return
      }
      return Promise.resolve(res)


	}, (error) => {

		reject(error.response.data);
		NProgress.done()

	}
)

function getLocalToken() {
	const token = 'Bearer' + window.localStorage.getItem('Token')
	return token
}

function fetch(url, params, methods, type,querytype) {
	NProgress.start();
	var config = {
		method: methods || 'POST',
		url: url,
	};

	if(type == 'file') {
		config.data = params
	} else {
    if(methods=="PUT"){
      config.data = JSON.stringify(params);
    }
		if(methods && (methods === 'post' || methods === 'POST')){
      if(querytype == 1){
        config.params = params;
      }else{
        config.data = JSON.stringify(params); //JSON.stringify(params); //obj.toJSONString() //JSON.stringify(params)
      }
		} else {
			config.params = params;
		};
	}

	return request(config)
}
export {
	fetch,
	request
}
